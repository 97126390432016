<template>
  <TopBarPPG />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
        <ol>
          <li class="text-danger"><a href="/">Início</a></li>
          <li><a href="/#servicos">Pix Parcelado Garantido</a></li>
        </ol>
        <h2>Pix Parcelado Garantido</h2>
      </div>
    </div>
  </section>

  <section class="inner-page mt-5">
    <div class="container">
    <div class="col-lg-12">
      <div style="margin-top: 50px"></div>

      <div class="row">
        <div class="col-lg-8 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
          <h5 style="border-bottom: 1px solid #BF1B39">Sobre o Produto</h5>
          <br />
          <p style="font-size: 13px">
            O Pix Parcelado Garantido será a nova ferramenta da Creditall que irá revolucionar a forma de vender e comprar em sua empresa!
          </p>
          <p style="font-size: 13px">
            O Pix é uma forma fácil, segura e rápida de transacionar dinheiro entre bancos e realizar pagamentos a qualquer momento direto do seu celular.
            Você poderá fazer e receber transferências instantâneas, pagar contas e compras do dia a dia a qualquer hora, sendo ele uma alternativa rápida
             e gratuita ao boleto, TED, DOC e até ao cartão de débito.
          </p>
          <p style="font-size: 13px">
            Segundo levantamento do Banco Central, esse meio de pagamento facilitou a vida de milhões de pessoas (físicas e jurídicas) que realizaram mais de 1 bilhão de transações entre novembro de 2020 e
            março de 2021. Neste período, foram R$ 787,2 bilhões transacionados.
          </p>
          <p style="font-size: 13px">
            Isso mostra que o Pix está, cada dia mais, sendo visto pelo público como um meio de pagamento, e não apenas como uma solução para transferência de valores entre pessoas físicas.
          </p>
          <p style="font-size: 13px">
            Ao invés de usar todos os dados bancários para receber transferências ou pagamentos, passando uma única informação, chamada de chave, o dinheiro chega na sua conta em segundos.
          </p>
          <p style="font-size: 13px">
            Quem diria que conseguiríamos fazer transferências gratuitas 24 horas por dia, sete dias por semana, 365 dias por ano usando um CPF, CNPJ, e-mail, telefone ou uma chave aleatória? Não podemos
            esquecer que é possível pagar compras via Pix escaneando o QRCode da maquininha.
          </p>
          <p style="font-size: 13px">
            E com o CreditallPay® Pix Parcelado Garantido isso ainda pode melhorar!
            O Pix Parcelado permitirá o parcelamento de compras presenciais e virtuais, assim como acontece com o cartão de crédito e, ainda por cima, com a garantia que só a Creditall oferece para você.
          </p>

          <br />

          <div class="row">
            <div class="col-md-6" style="margin-left: 20px">
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Otimização da gestão financeira</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Melhora no fluxo de caixa do negócio</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Potencial redução das tarifas cobradas pelas operadoras de cartão</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Garantia de recebimento dos valores parcelados</p>
            </div>
            <div class="col-md-4" style="margin-left: 20px">
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Grandes oportunidade de aumentar o ticket médio das vendas</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Expansão dos grupos de clientes atendidos</p>
              <div style="margin-top: 75px"></div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-left">
            <br>
          <img src="../../public/assets/images/sobre_pix1.jpg" class="img-fluid rounded shadow">
        </div>
      </div>
    </div>
    </div>
    <div style="margin-top: 50px"></div>

    <div class="col-lg-12">
    <div class="mb-5">
      <h5 class="text-center">Entre em contato para maiores informações</h5>
      <div class="d-grid gap-2 col-4 mx-auto">
        <a href="/cadastro-pix-parcelado-garantido" class="btn btn-danger" style="background: #BF1B39; border-radius: 0px;">Fale conosco</a>
      </div>
    </div>
    </div>
  </section>
  <a href="https://wa.me/5544991435010" class="back-to-top d-flex align-items-center justify-content-center" style="background-color:#34af23; border-radius: 20px">
    <i class="bi bi-whatsapp"></i>
  </a>
  <FooterPixParcelado />
</template>

<script>
import FooterPixParcelado from "../components/Footer.vue";
import TopBarPPG from "../components/TopBarPPG.vue";

export default {
  name: "SobreGA",
  components: {
    TopBarPPG,
    FooterPixParcelado,
  },
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}

@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
</style>
