<template>
  <TopBar />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
      <ol>
        <li class="text-danger"><a href="/">Início</a></li>
        <li><a href="/#servicos">Produtos e Serviços</a></li>
      </ol>
      <h2>Gestão de compras</h2>
    </div>
    </div>
  </section>

  <section class="inner-page mt-5">
    <div class="container">
      <div data-aos="fade-right">
        <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content">
          <div class="row">
            <h5 style="border-bottom: 1px solid red">Sobre o Produto</h5>
            <div style="margin-top: 25px"></div>
            <p style="font-size: 13px">
              A Solução Creditall Cartão Gestão de Compras é a forma mais segura
              e eficaz para agilizar o processo de concessão de crédito e
              facilitar a gestão de contas a receber na sua empresa, de forma
              prática e com baixo custo. Trata-se de um cartão sem taxas de
              adesão ou anuidades, que oferece crédito direto ao consumidor e
              até 40 dias para pagamento (Dependendo da data da compra), por
              meio de fatura ou boleto.
            </p>
            <p style="font-size: 13px">
              Ao implementar esta solução sua empresa contará com ferramentas
              avançadas de análise e concessão de crédito, avaliação
              comportamental e perfil de compras, sistemas de alerta e prevenção
              a fraudes com uso da biometria para identificação do consumidor,
              eliminação do risco e maior lucratividade em suas operações de
              crédito.
            </p>
            <p style="font-size: 13px">
              Para o seu cliente, além de crédito pré-aprovado e prazo para
              pagamento, contará com atendimento personalizado e sem burocracia
              com várias opções de data de vencimento, entre outros benefícios.
            </p>
            <p style="font-size: 13px">
              Com a solução Creditall Cartão Gestão de Compras sua empresa
              obterá aumento significativo em suas vendas, e o melhor, através
              do nosso sistema você passa a se relacionar mais com seus clientes
              reforçando a sua marca e alterando o hábito de compra dos mesmos,
              sua empresa terá acesso a relatórios completos de comportamento
              por:
            </p>
            <div style="margin-top: 50px"></div>
          </div>
        </div>

        <h6 style="border-bottom: 1px solid red">Pontos positivos de um Cartão de Compras:</h6>
        <div style="margin-top: 25px"></div>
        <div class="col-lg-12">
        <div class="row" style="margin-left: 20px">
          <div class="col-lg-6">
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Atrai os clientes e incrementa as vendas.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Estreita laços entre o cliente e a empresa.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Estimula o cliente a retornar ao estabelecimento.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Serve para diferenciar a empresa da concorrência.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Divulgação positiva da empresa e de sua marca.</p>
          </div>
        </div>
        </div>

        <div style="margin-top: 25px"></div>

        <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content">
        <div class="row">
            <p style="font-size: 13px">
              A Solução Creditall Garantia de Desempenho é uma plataforma de
              análise, decisão e concessão de crédito, que viabiliza a aquisição
              de novos clientes, padronizando e agilizando sua tomada de decisão
              de crédito, a plataforma permite a captura, a validação e o
              processamento de propostas de crédito em tempo real, além de
              automatizar a aplicação de políticas de crédito de acordo com a
              estratégia de seu negócio.
            </p>
            <p style="font-size: 13px">
              Abordagem customizada para a adequação perfeita ao seu tipo de
              negócio com aumento do retorno financeiro em suas vendas.
            </p>
          </div>
        </div>

        <div class="col-lg-12">
        <div class="row" style="margin-left: 20px">
          <div class="col-lg-6">
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Redução da inadimplência.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Melhoria do relacionamento com o cliente.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Aumento do potencial de utilização dos clientes rentáveis.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Redução dos custos de cobrança.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Maior lucratividade no relacionamento com clientes.</p>
          </div>
        </div>
        </div>

        <div style="margin-top: 25px"></div>


        <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content">
          <div class="row">
            <p style="font-size: 13px">
              O Sistema possui inteligência anti-fraude emitindo automaticamente
              mensagens de alertas baseadas em informações e características de
              empresas e/ou consumidores com comportamentos atípicos, gerando
              maior eficiência no processo de identificação positiva que evitam
              prejuízos para sua empresa e aprovações inconsistentes de
              créditos.
            </p>
            <p style="font-size: 13px">
              Benefícios: antecipa a identificação de empresas ou consumidores
              que estejam agindo de má-fé no comércio em geral ou em segmentos
              específicos, permitindo vender com maior segurança. As Soluções
              Anti-fraude reúnem as melhores e mais avançadas técnicas
              comportamentais para identificar situações com características de
              fraudes, protegendo sua empresa dos riscos potenciais de uma
              operação de crédito e, de perdas financeiras.
            </p>
            <p style="font-size: 13px">
              Características: Solução preditiva de riscos em transações de
              análise e concessão de crédito, mensagens automáticas motivadas
              por alterações comportamentais, compartilhamento de dados sobre
              inconsistências comerciais.
            </p>
            <div style="margin-top: 50px"></div>
          </div>
        </div>


        <h6 style="border-bottom: 1px solid red">Pontos positivos de um Cartão de Compras:</h6>
        <div style="margin-top: 25px"></div>

        <div class="col-lg-12">
        <div class="row" style="margin-left: 20px">
          <div class="col-lg-6">
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Capturar propostas de Crédito a partir de diferentes canais, enriquecendo, consistindo dados de proponentes e reduzindo potenciais riscos de fraudes a partir de fontes de dados internas e externas.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Segmentar cliente, por meio de um módulo de estratégia exclusivo.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Testar diferentes políticas de crédito e avaliar à aderência a estratégia do seu negócio.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Gerenciar propostas de crédito por meio de filas de trabalho em sistema de alçada de decisão.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Monitorar acesso, usuários e performance através de um módulo de auditoria e relatórios gerenciais.</p>
          </div>
        </div>
        </div>

        <div style="margin-top: 75px"></div>

        </div>
      </div>

      <div class="mb-5" data-aos="fade-right">
        <h5 class="text-center">Entre em contato para maiores informações</h5>
        <div class="d-grid gap-2 col-4 mx-auto">
          <a href="/quero-ser-cliente" class="btn btn-danger" style="border-radius: 0px;">Fale conosco</a>
        </div>
      </div>

  </section>
  <a href="https://wa.me/5544991435010" class="back-to-top d-flex align-items-center justify-content-center" style="background-color:#34af23; border-radius: 20px">
    <i class="bi bi-whatsapp"></i>
  </a>
  <Footer />
</template>

<script>
import TopBar from "../components/TopBar.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "GestaoCompras",
  components: {
    TopBar,
    Footer,
  },

    mounted() {

    if (window.localStorage) {

                if (!localStorage.getItem('reload')) {
                    localStorage['reload'] = true;
                    window.location.reload();
                } else {
                    localStorage.removeItem('reload');
                }
    }

  }
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
</style>
