<template>
  <TopBar />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
        <ol>
          <li class="text-danger"><a href="/">Início</a></li>
          <li><a href="/#servicos">Produtos e Serviços</a></li>
        </ol>
        <h2>Boleto Garantido</h2>
      </div>
    </div>
  </section>

  <section class="inner-page mt-5">
    <div class="container">
      <div class="col-lg-12">
      <div style="margin-top: 50px"></div>

      <div class="row">
        <div class="col-lg-8 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
          <h5 style="border-bottom: 1px solid #BF1B39">Sobre o Produto</h5>
          <br />
          <p style="font-size: 13px">
            O Boleto Garantido é uma solução destinada às empresas de varejo, atacado e comercio eletrônico
            que desejam efetuar vendas parceladas em até 12 vezes por meio do Boleto Bancário.
          </p>
          <p style="font-size: 13px">
            O Boleto Bancário devido a sua versatilidade, facilidade de controle
            e pagamento para o Consumidor é um dos principais meios de venda no
            comércio em geral.
          </p>
          <p style="font-size: 13px">
            Destacam-se principalmente os segmentos voltados para: Equipamentos
            e peças em geral, Eletro-Eletrônicos, Móveis & Decoração, Calçados,
            Confecções entre outros que tenham como característica o parcelamento de
            suas vendas.
          </p>
          <p style="font-size: 13px">
            O Boleto Garantido é a opção de pagamento em seu comércio.
          </p>
          <p style="font-size: 13px">
            Uma linha de crédito fácil, sem burocracia e com taxas competitivas,
            que irá ajudar a aumentar as suas vendas, seus clientes e sua
            lucratividade com maior fidelidade.
          </p>
          <p style="font-size: 13px">
            Creditall Boleto Garantido é a forma mais segura e eficaz para
            agilizar o processo de concessão de crédito e facilitar a gestão de
            contas a receber na sua empresa, de forma prática e com baixo custo.
            Trata-se de uma ferramenta de crédito poderosa que oferece crédito
            direto ao consumidor em até 12 parcelas para pagamento.
          </p>
          <p style="font-size: 13px">
            Ao implementar esta solução sua empresa contará com ferramentas
            avançadas de análise e concessão de crédito, avaliação
            comportamental e perfil de compras, sistema de alerta e prevenção a
            fraudes para a correta identificação do consumidor, eliminando risco
            e proporcionando maior lucratividade em suas operações de crédito.
          </p>
          <p style="font-size: 13px">
            Para o seu cliente, além de crédito pré-aprovado e prazo para
            pagamento, contará com atendimento personalizado e sem burocracia.
          </p>
          <p style="font-size: 13px">
            Com a solução Creditall Boleto Garantido seu comércio obterá aumento
            significativo em suas vendas, e o melhor, através do nosso sistema
            você passa a se relacionar mais com seus clientes reforçando a sua
            marca e alterando o hábito de compra dos mesmos, sua empresa terá
            acesso a relatórios completos de comportamento por:
          </p>
          <br />

          <div class="row">
            <div class="col-md-6" style="margin-left: 20px">
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Quantidade de compras</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Valor Médio por compra</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Prazo Médio e Parcelamento Máximo utilizado</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Clientes que mais Consomem</p>
            </div>
            <div class="col-md-4" style="margin-left: 20px">
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Dia da última compra</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Clientes inativos</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Dados Cadastrais</p>
              <div style="margin-top: 75px"></div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-left">
          <h5 style="border-bottom: 1px solid red">As informações Permitirão</h5>
          <br />
          <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Conhecer os clientes.</p>
          <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Criar estratégias de vendas.</p>
          <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Emissão de etiquetas para envio de mala direta.</p>
          <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Entre outras informações que permitirão que sua empresa conheça o hábito de compras de seus clientes proporcionando maior rentabilidade e aumento de suas vendas.</p>
          <div style="margin-top: 50px"></div>

          <h5 style="border-bottom: 1px solid red">Pontos Positivos</h5>
          <br />
          <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Atrai os clientes e incrementa as vendas.</p>
          <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Estreita laços entre o cliente e a empresa.</p>
          <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Estimula o cliente a retornar para novas compras.</p>
          <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Serve para diferenciar a empresa da concorrência.</p>
        </div>
      </div>
    </div>
    </div>
    <div style="margin-top: 50px"></div>

    <div class="col-lg-12">
    <div class="mb-5">
      <h5 class="text-center">Entre em contato para maiores informações</h5>
      <div class="d-grid gap-2 col-4 mx-auto">
        <a href="/quero-ser-cliente" class="btn btn-danger" style="background: #BF1B39; border-radius: 0px;">Fale conosco</a>
      </div>
    </div>
    </div>
  </section>
  <a href="https://wa.me/5544991435010" class="back-to-top d-flex align-items-center justify-content-center" style="background-color:#34af23; border-radius: 20px">
    <i class="bi bi-whatsapp"></i>
  </a>
  <Footer />
</template>

<script>
import Footer from "../components/Footer.vue";
import TopBar from "../components/TopBar.vue";

export default {
  name: "BoletoGarantidoo",
  components: {
    TopBar,
    Footer,
  },

    mounted() {

    if (window.localStorage) {

                if (!localStorage.getItem('reload')) {
                    localStorage['reload'] = true;
                    window.location.reload();
                } else {
                    localStorage.removeItem('reload');
                }
    }

  }
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}

@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
</style>
