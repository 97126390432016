<template>
  <TopBar />

  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
      <ol>
        <li class="text-danger"><a href="/">Início</a></li>
        <li><a href="/#lgpd">LGPD</a></li>
      </ol>
      <h2>LEI GERAL DE PROTEÇÃO DE DADOS (LGPD)</h2>
      </div>
    </div>
  </section>
  <!-- End Breadcrumbs -->

  <section class="inner-page mt-5">
    <div class="container">
      <div class="col-lg-12">
      <p>
        A Lei Geral de Proteção de Dados (Lei N° 13.079/2019), mais conhecida
        como LGPD, foi sancionada em 14 de agosto de 2018. Seu principal
        objetivo é estabelecer regras e limites no que diz respeito ao
        tratamento de dados pessoais, visando a proteção dos direitos
        fundamentais, como os de liberdade e privacidade de seus titulares.
      </p>

          <div id="accordion">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                  <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Quais são os principais conceitos abordados pela Lei? <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>

              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                    <p><strong>Dados Pessoais:</strong> são informações que possibilitam a identificação de uma pessoa. Ex: Nome Completo, Número do CPF, Telefone, Filiação, Endereço, dentre outras.</p>
                    <p><strong>Dados Sensíveis:</strong> são dados pessoais mais específicos e mais protegidos. Ex: origem racial ou étnica, convicção religiosa, opinião polícia, dados referente à saúde, dado genético ou biométrico, dentre outros.</p>
                    <p><strong>Dados Anonimizados:</strong> são informações nas quais não se consegue identificar o titular dos dados.</p>
                    <p><strong>Banco de Dados</strong>: é um conjunto de dados pessoais que ficam armazenados.</p>
                    <p><strong>Titular:</strong> pessoa à qual se referem os dados objeto de tratamento.</p>
                    <p><strong>Controlador:</strong> pessoa natural ou jurídica, pública ou privada, que tem o poder de decidir quanto ao tratamento dos dados.</p>
                    <p><strong>Operador:</strong> pessoa natural ou jurídica, pública ou privada, que trata os dados em nome do Controlador.</p>
                    <p><strong>Encarregado:</strong> pessoa encarregada pela comunicação entre Controlador, titular dos dados e Autoridade Nacional de Proteção de Dados (ANPD).</p>
                    <p><strong>Tratamento:</strong> toda operação envolvendo os dados pessoais, como a coleta, classificação, utilização, acesso, transmissão, reprodução, dentre outras.</p>
                    <p><strong>Consentimento:</strong> permissão dada pelo titular para que seus dados pessoais sejam tratados.</p>
                    <p><strong>Eliminação:</strong> exclusão dos dados.</p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Quais são os princípios de tratamento? <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body">
                    <p><strong>Finalidade:</strong> o tratamento deve ser realizado para propósitos legítimos, específicos, explícitos e informados ao titular.</p>
                    <p><strong>Adequação:</strong> o tratamento dos dados deve ser compatível com as finalidades informadas ao titular.</p>
                    <p><strong>Necessidade:</strong> o tratamento deve ser limitado ao mínimo necessário para realização das atividades.</p>
                    <p><strong>Livre Acesso:</strong> garantir aos titulares, de forma fácil e gratuita, acesso sobre a forma, duração do tratamento e integralidade dos dados.</p>
                    <p><strong>Qualidade dos Dados:</strong> garantir aos titulares que os dados tratados são exatos, claros e atualizados.</p>
                    <p><strong>Transparência:</strong> garantir aos titulares o fornecimento de informações precisas quanto ao tratamento dos dados, observando os segredos comerciais e industriais.</p>
                    <p><strong>Segurança:</strong> utilização de medidas voltadas à proteção dos dados.</p>
                    <p><strong>Prevenção:</strong> medidas adotadas para prevenir ocorrência de danos voltado ao tratamento de dados.;</p>
                    <p><strong>Não Discriminação:</strong> os dados não podem ser tratados para fins discriminatórios, ilícitos ou abusivos.</p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Quais são os principais direitos do titular dos dados? <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body">
                    <p>• Confirmação sobre a existência de tratamento;</p>
                    <p>• Acessar os dados;</p>
                    <p>• Correção dos dados tratados;</p>
                    <p>• Anonimização, bloqueio ou eliminação de dados desnecessários;</p>
                    <p>• Portabilidade de dados, observados os segredos comerciais eindustriais;</p>
                    <p>• Eliminação de dados;</p>
                    <p>• Revogação do consentimento.</p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFour">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Quais os benefícios da LGPD aos títulares? <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                <div class="card-body">
                    <p>
                      A LGPD trouxe aos títulares dos dados maior segurança quanto ao
                      tratamento de seus dados, podendo o mesmo acompanhar e
                      fiscalizar todo processo voltado ao manuseio de seus dados.
                    </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFive">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    A quem se aplica a LGPD? <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                <div class="card-body">
                    <p>
                      Aplica-se a qualquer operação de tratamento de dados, sendo
                      realizada por pessoal natural ou pessoa jurídica de direito
                      público ou privado.
                    </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingSix">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    Quando se dará o termino do tratamento dos dados? <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                <div class="card-body">
                    <p>• Quando identificado que a finalidade do tratamento foi alcançada ou não for mais pertinente/necessário ao alcance da finalidade especifica;</p>
                    <p>• Término do período de tratamento;</p>
                    <p>• Revogação do consentimento por parte do titular, atendendo as premissas da Lei;</p>
                    <p>• Quando for determinado pela autoridade nacional.</p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingSeven">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    Como faço para ter informações sobre meus dados junto à Creditall? <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                <div class="card-body">
                    <p>Caso você seja titular, poderá consultar as informações sobre seus dados através da ferramenta disponibilizada em nosso site.
                      <a href="/fatura-historico" class="text-danger">“CLIQUE AQUI”</a>.
                    </p>
                </div>
              </div>
            </div>
          </div>
      <div style="margin-top: 75px"></div>
      <p class="mb-5">Persistindo as dúvidas, deixamos a sua disposição nosso canal de atendimento
        <a href="mailto:relacionamento@creditall.com.br">relacionamento@creditall.com.br</a>. Através dele, nossa equipe prestará todo atendimento necessário.
      </p>
    </div>
    </div>


  </section>
  <a href="https://wa.me/5544991435010" class="back-to-top d-flex align-items-center justify-content-center" style="background-color:#34af23; border-radius: 20px">
    <i class="bi bi-whatsapp"></i>
  </a>
  <Footer />
</template>

<script>
import Footer from "../components/Footer.vue";
import TopBar from "../components/TopBar.vue";

export default {
  name: "LGPD",
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style scoped>
.btn-link {
    font-weight: 400;
    color: #444444;
    text-decoration:none;
}

.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}

.accordion-button:not(.collapsed) {
  color: #444444;
  background-color: #e4e4e4;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
</style>
