<template>
  <TopBar />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
      <ol>
        <li class="text-danger"><a href="/">Início</a></li>
        </ol>
        <h2>Trabalhe Conosco</h2>
    </div>
    </div>
  </section>
  <!-- End Breadcrumbs -->

  <section id="consumidor" class="consumidor">
    <div class="container">
      <div style="margin-top: 75px"></div>
      <div class="col-lg-12">
      <div class="row" data-aos="fade-up">

        <div class="col-lg-6 col-md-3">
          <div class="info-box mb-4">
            <i class="bx bi-clipboard-check" style="font-size: 40px; width: 72px; height: 72px"></i>
            <h3>Licenciado</h3>
            <p><a href="/licenciado" class="btn btn-danger" style="border-radius: 8px; padding: 8px;">Saiba mais</a></p>
          </div>
        </div>

        <div class="col-lg-6 col-md-3 mb-5">
          <div class="info-box mb-4">
            <i class="bx bi-person-workspace" style="font-size: 40px; width: 62px; height: 62px"></i>
            <h3>Funcionário</h3>
            <p><a href="/funcionario" class="btn btn-danger" style="border-radius: 8px; padding: 8px;">Saiba mais</a></p>
          </div>
        </div>
      </div>
      </div>

      <div style="margin-top: 75px"></div>
    </div>
  </section>
  <a href="https://wa.me/5544991435010" class="back-to-top d-flex align-items-center justify-content-center" style="background-color:#34af23; border-radius: 20px">
    <i class="bi bi-whatsapp"></i>
  </a>

  <Footer />
</template>

<script>
import Footer from "../components/Footer.vue";
import TopBar from "../components/TopBar.vue";

export default {
  name: "TrabalheConosco",
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}


.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}


.consumidor .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}
.consumidor .info-box i {
  font-size: 52px;
  color: #BF1B39;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #f5a0a0;
}
.consumidor .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}
.consumidor .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
section {
  padding: 30px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}
.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media (max-width: 575px) {
  .section-title h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  .section-title span {
    font-size: 38px;
  }
}

p:hover {
  color: #BF1B39;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 896px) {
  .row {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .consumidor .info-box {
    width: auto;
    margin: 0 auto;
    padding: 2rem 0 1.875rem 0;
  }

  .consumidor .info-box p {
    font-size: 12px;
  }
}
</style>
