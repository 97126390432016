<template>
  <TopBar />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
        <ol>
          <li class="text-danger"><a href="/">Início</a></li>
          <li><a href="/#servicos">Produtos e Serviços</a></li>
        </ol>
        <h2>Cartão Compras</h2>
      </div>
    </div>
  </section>

  <section class="inner-page mt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
          <h5 style="border-bottom: 1px solid red">Sobre o Produto</h5>
          <div style="margin-top: 25px"></div>
          <p style="font-size: 13px">
            A Solução Creditall Cartão de Compras é a forma mais segura e eficaz
            para agilizar o processo de concessão de crédito e facilitar a
            gestão de contas a receber na sua empresa, de forma prática e com
            baixo custo. Trata-se de um cartão sem taxas de adesão ou anuidades,
            que oferece crédito direto ao consumidor e até 40 dias para
            pagamento (Dependendo da data da compra), por meio de fatura ou
            boleto.
          </p>
          <p style="font-size: 13px">
            Ao implementar esta solução sua empresa contará com ferramentas
            avançadas de análise e concessão de crédito, avaliação
            comportamental e perfil de compras, sistema de alerta e prevenção a
            fraudes com uso da biometria para identificação do consumidor,
            eliminação do risco e maior lucratividade em suas operações de
            crédito.
          </p>
          <p style="font-size: 13px">
            Para o seu cliente, além de crédito pré-aprovado e prazo para
            pagamento, contará com atendimento personalizado e sem burocracia,
            com várias opções de data de vencimento, entre outros benefícios.
          </p>
          <p style="font-size: 13px">
            Com a solução Creditall Cartão de Compras sua empresa obterá aumento
            significativo em suas vendas, e o melhor, através do nosso sistema
            você passa a se relacionar mais com seus clientes reforçando a sua
            marca e alterando o hábito de compra dos mesmos, sua empresa terá
            acesso a relatórios completos de comportamento por:
          </p>
        </div>
      </div>

      <div class="row" style="margin-left: 20px" data-aos="fade-right">
        <div class="col-lg-6">
          <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39;"></i> Quantidade de compras.</p>
          <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39;"></i> Valor Médio por compra.</p>
          <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39;"></i> Prazo Médio e Parcelamento Máximo utilizado.</p>
          <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39;"></i> Clientes que mais Consomem</p>
        </div>
        <div class="col-lg-6">
          <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39;"></i> Dia da última compra.</p>
          <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39;"></i> Clientes inativos.</p>
          <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39;"></i> Endereço.</p>
          <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Aniversariantes do mês</p>
        </div>
      </div>

      <div style="margin-top: 75px"></div>

      <div class="row">
        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
          <h6 style="border-bottom: 1px solid red">As informações permitirão:</h6>
          <div style="margin-top: 25px"></div>
          <div class="row" style="margin-left: 20px">
            <div class="col-md-12">
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Conhecer os clientes.</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Criar estratégias de vendas.</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Emissão de etiquetas para envio de mala direta.</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Permitirá que sua empresa conheça o hábito de compras de seus clientes.</p>
            </div>
          </div>
        </div>

          <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
            <h6 style="border-bottom: 1px solid red">Pontos positivos de um Cartão de Compras:</h6>
            <br />
            <div class="row" style="margin-left: 20px">
              <div class="col-md-12">
                <p style="font-size: 13px"><i class="bi bi-bookmark-plus-fill text-danger"></i> Atrai os clientes e incrementa as vendas.</p>
                <p style="font-size: 13px"><i class="bi bi-bookmark-plus-fill text-danger"></i> Estreita laços entre o cliente e a empresa.</p>
                <p style="font-size: 13px"><i class="bi bi-bookmark-plus-fill text-danger"></i> Estimula o cliente a retornar ao estabelecimento.</p>
                <p style="font-size: 13px"><i class="bi bi-bookmark-plus-fill text-danger"></i> Serve para diferenciar a empresa da concorrência.</p>
                <p style="font-size: 13px"><i class="bi bi-bookmark-plus-fill text-danger"></i> Divulgação positiva da empresa e de sua marca.</p>
              </div>
            </div>
          </div>
      </div>

        <div style="margin-top: 50px"></div>

        <div class="row">

        </div>

        <div style="margin-top: 75px"></div>

        <div class="mb-5" data-aos="fade-right">
          <h6 class="text-center">Entre em contato para maiores informações</h6>
          <div class="d-grid gap-2 col-4 mx-auto">
            <a href="/quero-ser-cliente" class="btn btn-danger" style="border-radius: 0px;">Fale conosco</a>
          </div>
        </div>
      </div>

  </section>
  <a href="https://wa.me/5544991435010" class="back-to-top d-flex align-items-center justify-content-center" style="background-color:#34af23; border-radius: 20px">
    <i class="bi bi-whatsapp"></i>
  </a>
  <Footer />
</template>

<script>
import TopBar from "../components/TopBar.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "CartaoCompra",
  components: {
    TopBar,
    Footer,
  },

    mounted() {

    if (window.localStorage) {

                if (!localStorage.getItem('reload')) {
                    localStorage['reload'] = true;
                    window.location.reload();
                } else {
                    localStorage.removeItem('reload');
                }
    }

  }
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
</style>
