<template>
  <HeaderPixParcelado />

<div style="margin-top: 50px"></div>

<!----- INICIO ----->
<section id="inicio" class="inicio">
  <div style="margin-top: 50px"></div>
  <div class="container">
      <div class="col-lg-12">
        <div class="row justify-content-center">

          <div class="col-lg-5 d-flex" data-aos="fade-right">
            <img src="../../public/assets/images/sobre_pix.jpg" class="img-fluid rounded shadow" style="" alt=""/>
          </div>


          <div class="col-lg-6 pt-4 pt-lg-0 justify-content-center ml-2" data-aos="fade-right">
            <div class="section-title-inicio" data-aos="fade-up">
              <div class="section-title-inicio">
                <span>VENDA E PARCELE PELO CreditallPay® Pix Parcelado Garantido</span>
                <h2>VENDA E PARCELE PELO<br> CreditallPay® Pix Parcelado Garantido</h2>
              </div>
            </div>
            <p>Você já conhece a facilidade do Pix, uma forma fácil e segura de transacionar dinheiro entre bancos e realizar pagamentos a qualquer momento!</p>
            <p>Agora, a  Creditall apresenta o Pix Parcelado Garantido, um novo produto CreditallPay® que permitirá efetuar vendas parceladas de forma simples e rápida, com total segurança e ainda 100% garantida em caso de inadimplência.</p>
            <a href="/sobre-pix-parcelado-garantido" class="btn btn-danger" style="margin-top: 1rem; border-radius: 6px;">Conheça nosso novo produto</a>
            <div style="margin-top: 25px"></div>
          </div>


        </div>
      </div>
    </div>
</section>
<!----- FIM INICIO ----->



<div style="margin-top: 100px"></div>



<!----- COMO FUNCIONA ----->
<section id="comofuncionaparceiro" class="comofuncionaparceiro">
<div style="margin-top: 75px"></div>

  <div class="container">
    <div class="section-title-comofuncionaparceiro">
      <span>PARA NOSSOS PARCEIROS <br>COMO FUNCIONA O CreditallPay® Pix Parcelado Garantido</span>
      <h2>PARA NOSSOS PARCEIROS <br>COMO FUNCIONA O CreditallPay® Pix Parcelado Garantido</h2>
    </div>


    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
        <div class="icon-box">
          <div class="icon"><p class="fw-bold" style="font-size: 20px">1</p></div>
          <h4 style="border-bottom: 1px solid red">Primeiro a gente se conhece</h4>
          <div class="text">
            <p>A loja se credencia, assina um contrato de parceria e recebe um login e senha para acessar o sistema.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
        <div class="icon-box">
          <div class="icon"><p class="fw-bold" style="font-size: 20px">2</p></div>
          <h4 style="border-bottom: 1px solid red">Você vende no Pix</h4>
          <div class="text">
            <p>As vendas no Pix Parcelado Garantido são efetuadas seguindo as regras habituais da parceria.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
        <div class="icon-box">
          <div class="icon"><p class="fw-bold" style="font-size: 20px">3</p></div>
          <h4 style="border-bottom: 1px solid red">Nós pagamos a você</h4>
          <div class="text">
            <p>O que vender, você recebe o valor parcelado conforme o plano de pagamento escolhido pelo seu cliente.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
        <div class="icon-box">
          <div class="icon"><p class="fw-bold" style="font-size: 20px">4</p></div>
          <h4 style="border-bottom: 1px solid red">Receba o pagamento das parcelas</h4>
          <div class="text">
            <p>O cliente paga as parcelas pelo próprio celular de forma rápida e prática.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
        <div class="icon-box">
          <div class="icon"><p class="fw-bold" style="font-size: 20px">5</p></div>
          <h4 style="border-bottom: 1px solid red">A inadimplência é nossa!</h4>
          <div class="text">
            <p>Se seu cliente não pagar, a Creditall paga! Deixe com a gente a parte chata de cobrar.⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
        <div class="icon-box">
          <div class="icon"><p class="fw-bold" style="font-size: 20px">6</p></div>
          <h4 style="border-bottom: 1px solid red">Venda muito mais!</h4>
          <div class="text">
            <p>Com o Pix Parcelado Garantido, você vende mais e seu cliente fica feliz.</p>
          </div>
        </div>
      </div>

    </div>
    <div style="margin-top: 100px"></div>
  </div>
</section>


<div style="margin-top: 75px"></div>


<section id="comofuncionacliente" class="comofuncionacliente">
  <div class="page-center">
    <div class="col-lg-12">
    <h3>Para o seu cliente</h3>
    <h3>Como funciona o CreditallPay® Pix Parcelado Garantido</h3>
    <div class="row cm-how-steps" style="justify-content">

        <div style="margin-top: 50px"></div>

        <div class="row">
        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon"><i class="fas fa-dollar-sign" style="font-size: 25px;"></i></div>
            <h4 class="title"><p class="fw-bold;" style="font-size: 18px;">1</p></h4>
            <p class="description">A loja inclui o valor da compra...</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon"><i class="fas fa-list-ol" style="font-size: 25px;"></i></div>
            <h4 class="title"><p class="fw-bold;" style="font-size: 18px;">2</p></h4>
            <p class="description">...e combina com seu cliente o número de parcelas (Máximo até 12 Parcelas)</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300">
            <div class="icon"><i class="fas fa-calculator" style="font-size: 25px;"></i></div>
            <h4 class="title"><p class="fw-bold;" style="font-size: 18px;">3</p></h4>
            <p class="description">Opcionalmente, a loja poderá repassar juros ao seu cliente que será acrescido ao valor de cada parcela.</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon"><i class="fas fa-qrcode" style="font-size: 25px;"></i></div>
            <h4 class="title"><p class="fw-bold;" style="font-size: 18px;">4</p></h4>
            <p class="description">No momento da compra, será gerado para cada mês do parcelamento uma QR code Pix, assim o seu cliente conseguirá
                pagar cada parcela no devido vencimento.</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon"><i class="fas fa-search" style="font-size: 25px;"></i></div>
            <h4 class="title"><p class="fw-bold;" style="font-size: 18px;">5</p></h4>
            <p class="description">Acompanhamos mês a mês cada parcela, diferenciamos quais foram pagas ou não..</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300">
            <div class="icon"><i class="fas fa-check" style="font-size: 25px;"></i></div>
            <h4 class="title"><p class="fw-bold;" style="font-size: 18px;">6</p></h4>
            <p class="description">Assim que seu cliente pagar nos te avisamos!</p>
        </div>
        </div>

    </div>
  </div>
  </div>
</section>
<!----- FIM COMO FUNCIONA ----->


<div style="margin-top: 50px"></div>


<!----- BENEFICIOS ----->
<section id="beneficios" class="beneficios">
<div style="margin-top: 75px"></div>
  <div class="container">
  <div class="section-title" data-aos="fade-up">
    <div class="section-title-beneficios">
      <span>VEJA OS BENEFÍCIOS QUE O <br>CreditallPay® Pix Parcelado Garantido OFERECE:</span>
      <h2>VEJA OS BENEFÍCIOS QUE O <br>CreditallPay® Pix Parcelado Garantido OFERECE:</h2>
    </div>
  </div>

      <div class="col-lg-12 d-flex justify-content-center">
        <div class="col-lg-10">
          <div class="row justify-content-center">

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>POTENCIAL REDUÇÃO DAS TARIFAS COBRADAS PELAS OPERADORAS DE CARTÃO</h3>
                <p>Considerando a principal característica dessa função, é bem provável que ela entre em concorrência direta com os cartões de crédito.
                   Assim, para não perder campo de atuação, a expectativa é que as operadoras reduzam taxas e juros, tanto para consumidores quanto para
                   lojistas, a fim de fomentar a utilização das suas soluções.</p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>GARANTIA DE RECEBIMENTO DE VALORES PARCELADOS</h3>
                <p>O Pix Garantido não tem esse nome à toa! Suas vendas parceladas terão 100% de garantia, permitindo ao nosso cliente a certeza no
                    recebimento dos valores parcelados. Isso acontece porque o CreditallPay® funciona como intermediador nas transações via Pix, se seu
                    consumidor não pagar a Creditall paga!</p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>GRANDE OPORTUNIDADE PARA AUMENTAR O TICKET MEDIO EM SUA VENDAS</h3>
                <p>Com o Pix Parcelado e Garantido, você aumentará expressivamente o valor do ticket médio em suas vendas, pois até o momento, não há
                    indicações que apontam que o Pix terá limites de valores, assim como acontece com os cartões de crédito. Se isso se mantiver, os
                    comerciantes têm grandes oportunidades de aumentar os seus tickets médios, pois os clientes poderão usar essa ferramenta para
                    realizar compras de maior valor agregado.</p>
              </div>
            </div>


            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>EXPANSÃO DOS GRUPOS DE CLIENTES ATENDIDOS</h3>
                <p>Para fazer um parcelamento no Pix o consumidor não precisa ter cartão de crédito, apenas uma conta bancária vinculada a essa solução.
                   Assim, as empresas varejistas que oferecerem esse meio de pagamento, terão a chance de atender novos grupos de consumidores,
                   a exemplo daqueles que não têm esse tipo de cartão, ou mesmo os que têm, mas não querem comprometer o limite.</p>
              </div>
            </div>

            </div>

        </div>
      </div>
  </div>
</section>
<!----- FIM BENEFICIOS ----->


<div style="margin-top: 50px"></div>


<!----- VANTAGENS ----->
<section id="vantagens" class="vantagens">
<div style="margin-top: 75px"></div>
<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-12">
      <div class="row justify-content-center">
        <div class="col-lg-5 justify-content-start content" data-aos="fade-right">
          <div class="section-title" data-aos="fade-up">
              <div style="margin-top: 25px"></div>
            <div class="section-title-vantagens">
              <span>Vantagens</span>
              <h2>Vantagens</h2>
            </div>
            <div style="margin-left: 20px">
                <p style="font-size: 14px"><i class="bi bi-check2 text-danger"></i> 100% de ressarcimento em caso de inadimplência.</p><br>
                <p style="font-size: 14px"><i class="bi bi-check2 text-danger"></i> Inteligência anti-fraude com uso de biometria facial*.</p><br>
                <p style="font-size: 14px"><i class="bi bi-check2 text-danger"></i> Aumento nos recebimentos das vendas a prazo.</p><br>
                <p style="font-size: 14px"><i class="bi bi-check2 text-danger"></i> Maior Lucratividade nas operações de crédito.</p><br>
                <p style="font-size: 14px"><i class="bi bi-check2 text-danger"></i> Mais segurança nas vendas devido à identificação do perfil e potencial do consumidor.</p><br>
                <p style="font-size: 14px"><i class="bi bi-check2 text-danger"></i> Agilidade nas aprovações de crédito proporcionando diminuição no tempo médio do atendimento.</p>
                <div style="margin-top: 25px"></div>
            </div>
            <p style="font-size: 13px">*De acordo com o módulo anti-fraude contratado.</p>
            <div style="margin-top: 25px"></div>
          </div>
        </div>
        <div class="col-lg-6 d-flex justify-content-center" data-aos="fade-left">
          <img src="assets/images/aplicacoes_pix.jpg" class="img-fluid rounded shadow" style="shadow" alt=""/>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
<!----- FIM VANTAGENS ----->



<div style="margin-top: 50px"></div>


<!----- PARCEIROS ----->
<section id="parceiros" class="parceiros">
<div style="margin-top: 75px"></div>
  <div class="section-title-parceiros">
    <span>Parceiros</span>
    <h2>Parceiros</h2>
  </div>

  <section id="logos" class="logos">
    <div class="container" data-aos="zoom-in">
      <div class="col-lg-12">
        <div class="row d-flex justify-content-center align-items-center">

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../public/assets/images/clients/pecunia2.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../public/assets/images/clients/RGS.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../public/assets/images/clients/3.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../public/assets/images/clients/DS-Logo-Black1.png" class="img-fluid" alt="">
          </div>

        </div>
      </div>
    </div>
  </section>


</section>
<!----- FIM PARCEIROS ----->

<div style="margin-top: 50px"></div>


<!----- QUERO SER ----->
<section id="queroser" class="queroser">
<div style="margin-top: 75px"></div>
  <div class="section-title-quero">
    <span>Quero ser Cliente</span>
    <h2>Quero ser Cliente</h2>
  </div>
  <div class="mb-5" data-aos="fade-right">
    <h5 class="text-center">Seja parceiro e venda muito mais com o <br>CreditallPay® Pix Parcelado Garantido</h5>
    <div class="d-grid gap-2 col-4 mx-auto" style="margin-top: 25px">
      <a href="/cadastro-pix-parcelado-garantido" class="btn btn-danger" style="border-radius: 6px;">Fale conosco</a>
    </div>
  </div>
</section>
<!----- FIM QUERO SER ----->

<div style="margin-top: 100px;"></div>

<a href="https://wa.me/5544991435010" class="back-to-top d-flex align-items-center justify-content-center" style="background-color:#34af23; border-radius: 20px">
  <i class="bi bi-whatsapp"></i>
</a>

<FooterPixParcelado />
</template>


<script>
import FooterPixParcelado from "../components/Footer.vue";
import HeaderPixParcelado from "../components/HeaderPixParcelado.vue";

export default {
  name: "PixParcelado",
  components: {
    HeaderPixParcelado,
    FooterPixParcelado,
  },
};


</script>

<style scoped>
/*------------------*/
/*----- INICIO -----*/
.section-title-inicio {
  text-align: center;
  padding: 20px 0;
  position: relative;
}
.section-title-inicio h2 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-inicio span {
  position: absolute;
  top: 0px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 42px;
  line-height: 1;
}
.section-title-inicio p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}
/*----- FIM INICIO -----*/
/*----------------------*/

/*-------------------------*/
/*----- COMO FUNCIONA -----*/
.icon-box a#botao.btn.btn-danger.text-light {
  background-color:#BF1B39;
  position: absolute;
  bottom: 0;
  border: #fff;
}

.comofuncionaparceiro .icon-box {
  padding: 60px 30px;
  transition: all ease-in-out 0.3s;
  background: #fefefe;
  box-shadow: 0px 50px 90px 0px rgba(110, 123, 131, 0.1);
  border-radius: 18px;
  border-bottom: 5px solid #fff;
}


.comofuncionaparceiro .icon-box .icon {
  width: 58px;
  height: 58px;
  background: #BF1B39;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
  color: #fff;
}


.comofuncionaparceiro .icon-box .icon-botao{
  width: 44px;
  height: 44px;
  background: #BF1B39;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.comofuncionaparceiro .icon-box img {
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: -5rem;
  left: 91%;
}

.comofuncionaparceiro .icon-box .icon i {
  font-size: 28px;
}
.comofuncionaparceiro .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}
.comofuncionaparceiro .icon-box h4 a {
  color: #282828;
  transition: ease-in-out 0.3s;
}
.comofuncionaparceiro .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.comofuncionaparceiro .icon-box:hover {
  transform: translateY(-10px);
  border-color: #BF1B39;
}
.comofuncionaparceiro .icon-box:hover h4 a {
  color: #BF1B39;
}

/* media 1 */

.section-title-comofuncionaparceiro {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title-comofuncionaparceiro h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-comofuncionaparceiro span {
  position: absolute;
  top: 0px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 28px;
  line-height: 1;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-comofuncionaparceiro span {
        font-size:28px;
        top: 30px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-comofuncionaparceiro span {
        font-size:28px;
        top: 30px;
    }
}

@media(max-width:767px){
    .section-title-comofuncionaparceiro span {
        font-size:28px;
        top: 30px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-comofuncionaparceiro span {
        font-size: 28px;
        top: 30px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-comofuncionaparceiro span {
        font-size:25px;
        top: 30px;
    }
}


.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}



.comofuncionacliente {
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: 30px 0;
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../public/assets/images/pix1.png") fixed center center;
  background-size: cover;
  padding: 120px 0;
}
.comofuncionacliente h3 span {
  color: #fff;
}

.page-center, .dnd-section > .row-fluid, .content-wrapper {
  max-width: 1088px;
}
.dnd-section > .row-fluid, .page-center {
  margin: 0 auto;
}

.comofuncionacliente .cm-how-steps .step {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}


.comofuncionacliente .cm-how-steps .step .circle {
  position: relative;
  font-family: "Gotham", sans-serif;
  font-size: 46px;
  font-weight: 700;
  line-height: 64px;
  width: 89px;
  height: 89px;
  display: flex;
  margin-top: 25px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .comofuncionacliente .cm-how-steps .step .circle {
      font-size: 40px;
      width: 79px;
      height: 79px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .comofuncionacliente .cm-how-steps .step .circle {
      font-size: 35px;
      width: 69px;
      height: 69px;
    }
}

@media(max-width:767px){
    .comofuncionacliente .cm-how-steps .step .circle {
      font-size: 30px;
      width: 59px;
      height: 59px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .comofuncionacliente .cm-how-steps .step .circle {
      font-size: 30px;
      width: 59px;
      height: 59px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .comofuncionacliente .cm-how-steps .step .circle {
      font-size: 25px;
      width: 49px;
      height: 49px;
    }
}


.comofuncionacliente .cm-how-steps .step p {
  font-family: "Open Sans", sans-serif;
  line-height: 28px;
  letter-spacing: -0.5px;
  margin-top: 22px;
  width: 185px;
  margin-bottom: 0;
}


.comofuncionacliente p {
  margin-bottom: 0;
}
p {
  margin: 0 0 1.4rem;
}
.comofuncionacliente .cm-how-steps .step .circle:after {
  content: "";
  position: absolute;
  right: -90px;
  width: 44px;
  height: 30px;
}

.comofuncionacliente .cm-how-steps {
  display: flex;
  margin: 88px 0;
}

.comofuncionacliente h3 {
  color: #fff;
  letter-spacing: -0.8px;
  font-size: 26px;
  line-height: 36px;
  font-weight: 700;
}


.comofuncionacliente h4 {
  font-size: 26px;
  line-height: 36px;
  letter-spacing: -0.8px;
  margin-bottom: 8px;
  font-weight: 700;
  font-family: "Gotham Rounded", sans-serif;
  color: #fff;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .comofuncionacliente h4 {
      font-size: 24px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .comofuncionacliente h4 {
      font-size: 22px;
    }
}

@media(max-width:767px){
    .comofuncionacliente h4 {
      font-size: 22px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .comofuncionacliente h4 {
      font-size: 20px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .comofuncionacliente h4 {
      font-size: 18px;
    }
}

.comofuncionacliente .icon-box {
  margin-bottom: 20px;
  text-align: center;
}
.comofuncionacliente .icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 50%;
  transition: 0.5s;
  color: #BF1B39;
  overflow: hidden;
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15);
}
.comofuncionacliente .icon i {
  font-size: 36px;
  line-height: 0;
}
.comofuncionacliente .icon-box:hover .icon {
  box-shadow: 0px 0 25px rgba(233, 66, 66, 0.3);
}
.comofuncionacliente .title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
  position: relative;
  padding-bottom: 15px;
}
.comofuncionacliente .title a {
  color: #444444;
  transition: 0.3s;
}
.comofuncionacliente .title a:hover {
  color: #BF1B39;
}
.comofuncionacliente .title::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 2px;
  background: #BF1B39;
  bottom: 0;
  left: calc(50% - 25px);
}
.comofuncionacliente .description {
  line-height: 24px;
  font-size: 14px;
}

.card-body {
  background: #f2f2f2;
  opacity: 0.9;
}

/*----- FIM COMO FUNCIONA -----*/
/*-----------------------------*/


/*----------------------*/
/*----- BENEFICIOS -----*/
.beneficios .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 40px 40px 30px 40px;
}
.beneficios .info-box i {
  font-size: 52px;
  color: #BF1B39;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #f5a0a0;
}
.beneficios .info-box h3 {
  font-size: 20px;
  color: #444444;
  font-weight: 700;
  margin: 10px 0;
}
.beneficios .info-box p {
  padding: 20px;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .beneficios .info-boxs span {
      font-size: 40px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .beneficios .info-box span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media(max-width:767px){
    .beneficios .info-box span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .beneficios .info-box span {
      font-size: 35px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .beneficios .info-box span {
      font-size: 20px;
      line-height: 0;
    }
}

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title-beneficios {
  text-align: center;
  padding: 20px;
  position: relative;
}
.section-title-beneficios h2 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-beneficios span {
  position: absolute;
  top: 0px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
}
.section-title-beneficios p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

/*----- FIM BENEFICIOS -----*/
/*--------------------------*/



/*------------------*/
/*----- VANTAGENS -----*/
section {
  padding: 30px 0;
  overflow: hidden;
}

.section-title-vantagens {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title-vantagens h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-vantagens span {
  position: absolute;
  top: 0px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 42px;
  text-transform: uppercase;
}
.section-title-vantagens p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-vantagens span {
      font-size: 40px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-vantagens span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media(max-width:767px){
    .section-title-vantagens span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-vantagens span {
      font-size: 35px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-vantagens span {
      font-size: 20px;
      line-height: 0;
    }
}

/*----- FIM VANTAGENS -----*/
/*----------------------*/



/*---------------------*/
/*----- VANTAGENS -----*/
.icon-box a#botao.btn.btn-danger.text-light {
  background-color:#BF1B39;
  position: absolute;
  bottom: 0;
  border: #fff;
}

.vantagens .icon-box {
  padding: 60px 30px;
  transition: all ease-in-out 0.3s;
  background: #fefefe;
  box-shadow: 0px 50px 90px 0px rgba(110, 123, 131, 0.1);
  border-radius: 18px;
  border-bottom: 5px solid #fff;
}


.vantagens .icon-box .icon {
  width: 64px;
  height: 64px;
  background: #BF1B39;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
  color: #fff;
}


.vantagens .icon-box .icon-botao{
  width: 44px;
  height: 44px;
  background: #BF1B39;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.vantagens .icon-box img {
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: -5rem;
  left: 91%;
}

.vantagens .icon-box .icon i {
  font-size: 28px;
}
.vantagens .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}
.vantagens .icon-box h4 a {
  color: #282828;
  transition: ease-in-out 0.3s;
}
.vantagens .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.vantagens .icon-box:hover {
  transform: translateY(-10px);
  border-color: #BF1B39;
}
.vantagens .icon-box:hover h4 a {
  color: #BF1B39;
}

/* media 1 */

.section-title-vantagens {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title-vantagens h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-vantagens span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-vantagens span {
        font-size:55px;
        top: 30px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-vantagens span {
        font-size:48px;
        top: 30px;
    }
}

@media(max-width:767px){
    .section-title-vantagens span {
        font-size:35px;
        top: 30px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-vantagens span {
        font-size: 30px;
        top: 30px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-vantagens span {
        font-size:25px;
        top: 30px;
    }
}


.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

/*----- FIM VANTAGENS -----*/
/*-------------------------*/



/*---------------------*/
/*----- SEGURANCA -----*/
section {
  padding: 30px 0;
  overflow: hidden;
}

.section-title-seguranca {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title-seguranca h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-seguranca span {
  position: absolute;
  top: 1px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 42px;
  text-transform: uppercase;
  line-height: 1;
}
.section-title-seguranca p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-seguranca span {
      font-size: 42px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-seguranca span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media(max-width:767px){
    .section-title-seguranca span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-seguranca span {
      font-size: 35px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-seguranca span {
      font-size: 28px;
    }
}

/*----- FIM SEGURANCA -----*/
/*-------------------------*/



/*---------------------*/
/*----- PARCEIROS -----*/
.section-title-parceiros {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title-parceiros h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-parceiros span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}
.section-title-parceiros p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}


.logos {
  background: #f2f2f2;
  padding: 15px 0;
  text-align: center;
}
.logos img {
  width: 50%;
  filter: grayscale(100);
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}
.logos img:hover {
  filter: none;
  transform: scale(1.2);
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-parceiros span {
      font-size: 42px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-parceiros span {
      font-size: 38px;
    }
}

@media(max-width:767px){
    .section-title-parceiros span {
      font-size: 35px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-parceiros span {
      font-size: 32px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-parceiros span {
      font-size: 30px;
    }
}

/*----- FIM PARCEIROS -----*/
/*-------------------------*/



/*---------------------*/
/*----- QUERO SER -----*/
section {
  padding: 30px 0;
  overflow: hidden;
}

.section-title-quero {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title-quero h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-quero span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}
.section-title-quero p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-quero span {
      font-size: 42px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-quero span {
      font-size: 38px;
    }
}

@media(max-width:767px){
    .section-title-quero span {
      font-size: 35px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-quero span {
      font-size: 32px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-quero span {
      font-size: 30px;
    }
}


/*----- FIM QUERO SER -----*/
/*-------------------------*/

.vantagens .icon-box .icon i {
  font-size: 28px;
}
.vantagens .icon-box h4 {
  font-weight: 700;
  justify-content: center;
  margin-bottom: 15px;
  font-size: 24px;
}
.vantagens .icon-box h4 a {
  color: #282828;
  transition: ease-in-out 0.3s;
}
.vantagens .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.vantagens .icon-box:hover {
  transform: translateY(-10px);
  border-color: #BF1B39;
}
.vantagens .icon-box:hover h4 a {
  color: #BF1B39;
}



/*----- FIM VANTAGENS -----*/


</style>
