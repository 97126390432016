<template>
  <section class='bord' style="background-image: url('assets/images/BANNERS0001.png')" >
    <div class="container">
      <div class='title' data-aos="fade-right" >
        <h1 style="color:#fff; font-weight:bold; margin: 80px 0;"> <font face = "Sansation_Regular">Serviços e soluções para acompanhar a sua empresa em cada etapa do seu negócio.</font></h1>
        <h5 style="color:#fff; font-weight:100; margin: 20px 0;"> <font face = "Sansation_Regular">Protegemos o seu negócio, protegemos o seu mercado.</font></h5>
        <div  class='divider'></div>
        <h1 style="color:#fff; font-weight:bold; margin: 20px 0;"> <font face = "Sansation_Regular">Garantia de Crédito.</font></h1>
        <h5 style="color:#fff; font-weight:100;"> <font face = "Sansation_Regular">Para impulsionar o crescimento da sua empresa a chegar a novos mercados, sempre de maneira <br> rentável e segura.</font></h5>
        <div class='divSaiba' >
          <div class='iconSaiba'>
            <i class="bi bi-caret-right"></i>
          </div>
          <a class='textSaiba' href="/quero-ser-cliente">Saiba Mais</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionQueroSerCliente",
};
</script>

<style scoped>
section {
  padding: 30px 0;
  overflow: hidden;
}
.divLogo{
  width: 400px;
}
.textFooter{

  text-align: justify;
  font-size: 18px;
  color: #fff;
  margin: 0 40px;
  line-height: 33px;
}
.footerHomepage{
  display: flex;
  align-items: center;
  padding: 80px 0;
  justify-content: center;
}
.divSaiba{
  display: flex;
  align-items: center;
  width: 30%;
}
.textSaiba{
  color:#fff; 
  font-weight:200; 
  margin:0;
}

.iconSaiba{
  color: #BF1B39;
  font-size: 35px;
}

.divSaiba:hover .textSaiba{
    color: #BF1B39;
}
.bord{
  background: url("../../public/assets/images/Prancheta2.png") top center;
  background-size: cover;
  background-color: rgba(43,42,61,.7);
  padding: 90px 0 30px 0;
}
.divider{
  background-color: #BF1B39;
  border-radius: 10px;
  width: 60%;
  height: 8px;
  margin: 20px 0

}
.section-title {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}
.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title span {
        font-size:40px;
        top: 30px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title span {
        font-size:48px;
        top: 30px;
    }
}

@media(max-width:767px){
    .section-title span {
        font-size:35px;
        top: 30px;
    }
    .footerHomepage{
      flex-direction: column;
      justify-content: space-between;
      padding: 40px 0;
    }
}


@media(max-width:768px){
    .footerHomepage{
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 0;
    }
    .divLogo{
      left: 0;
      width: 100px;
      margin: 20px 0;
    }
}


@media only screen and (min-width:576px) and (max-width:767px){
    .section-title span {
        font-size: 30px;
        top: 30px;
    }
    
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title span {
        font-size:25px;
        top: 30px;
    }
}

@media only screen and (min-width:300) and (max-width:440){
    .section-title span {
        font-size:15px;
        top: 30px;
    }
}
</style>

