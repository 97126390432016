<template>
  <TopBar />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
        <ol>
          <li class="text-danger"><a href="/">Início</a></li>
          <li><a href="/#servicos">Produtos e Serviços</a></li>
        </ol>
        <h2>Garantia de Crediário</h2>
      </div>
    </div>
  </section>

  <section class="inner-page mt-5">
    <div class="container">
      <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
          <div class="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <h4 style="border-bottom: 1px solid red">Vídeo Crediário</h4>
          </div>
          <iframe src="https://www.youtube.com/embed/w3JMniOhs_U" width="100%" height="400"></iframe>
        </div>

        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
          <div class="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <h4 style="border-bottom: 1px solid red">Vídeo Comercial</h4>
          </div>
          <iframe src="https://www.youtube.com/embed/-NsDPyyv540" width="100%" height="400"></iframe>
        </div>
      </div>
      </div>
      <div style="margin-top: 50px"></div>


      <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
        <div class="row">
          <h5 style="border-bottom: 1px solid red">Sobre o Produto</h5>
          <div style="margin-top: 25px"></div>
          <p style="font-size: 13px">
            O Crediário devido a sua versatilidade, facilidade de controle e
            pagamento para o Cliente é um dos principais meios de venda para
            grandes redes varejistas, destacam-se principalmente os segmentos
            voltados para: Eletro-Eletrônicos, Móveis & Decoração, Calçados,
            Confecções e outros que tenham como característica o parcelamento de
            suas vendas.
          </p>
          <p style="font-size: 13px">
            Creditall Garantia de Crediário é a opção de pagamento em seu
            estabelecimento. Uma linha de crédito fácil, sem burocracia e com
            taxas competitivas, que irá ajudar a aumentar as suas vendas, seus
            clientes e sua lucratividade.
          </p>
          <div style="margin-top: 50px"></div>
        </div>
      </div>

                <div style="margin-top: 50px"></div>
            <h5 style="border-bottom: 1px solid red">Seu relacionamento com o nosso Crediário</h5>
            <div style="margin-top: 50px"></div>
            <div class="col-lg-12">
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                      <h6><span style="color: #BF1B39;">1.</span> Primeiro a gente se conhece</h6>
                      <p class="card-text text-muted" style="font-size: 13px">A loja se credencia, assina um contrato de parceria e recebe um login e senha para acessar o
                          sistema.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                      <h6><span style="color: #BF1B39;">2.</span> Você vende no carnezinho</h6>
                    <p class="card-text text-muted" style="font-size: 13px">
                      As vendas no crediário são efetuadas seguindo as regras habituais da parceria e os carnês são assinados pelos seus clientes.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                      <h6><span style="color: #BF1B39;">3.</span> Nós pagamos a você</h6>
                    <p class="card-text text-muted" style="padding-right: 1.5rem font-size: 13px">
                      O que vender, você recebe o valor parcelado conforme o plano de pagamento escolhido pelo seu cliente.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                      <h6><span style="color: #BF1B39;">4.</span> Receba o pagamento das parcelas</h6>
                    <p class="card-text text-muted" style="font-size: 13px">
                     O cliente paga as parcelas na própria loja onde a compra foi realizada através do carnê.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                      <h6><span style="color: #BF1B39;">5.</span> A inadimplência é nossa!</h6>
                    <p class="card-text text-muted" style="font-size: 13px">
                      Se seu cliente não pagar, a Creditall paga! Deixe com a gente a parte chata de cobrar.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                      <h6><span style="color: #BF1B39;">6.</span> Ah! Quero modernizar</h6>
                    <p class="card-text text-muted" style="font-size: 13px">
                      No nosso Crediário você pode habilitar a opção de recebimento através do boleto bancário ou Pix.
                      </p>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div style="margin-top: 50px"></div>


      <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
        <div class="row">
          <h6 style="border-bottom: 1px solid red">Benefícios com o Produto:</h6>
          <div style="margin-top: 25px"></div>
          <div style="margin-left: 20px">
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> 100% de ressarcimento em caso de inadimplência.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Inteligência anti-fraude com uso de biometria.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Aumento nos recebimentos das vendas a prazo.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Maior Lucratividade nas operações de crédito.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Mais segurança nas vendas devido à identificação do perfil e potencial do consumidor.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Agilidade nas aprovações de crédito proporcionando diminuição no tempo médio do atendimento.</p>
            <div style="margin-top: 50px"></div>
          </div>
        </div>
      </div>

      <div class="mb-5" data-aos="fade-right">
        <h5 class="text-center">Entre em contato para maiores informações</h5>
        <div class="d-grid gap-2 col-4 mx-auto">
          <a href="/quero-ser-cliente" class="btn btn-danger" style="border-radius: 0px;">Fale conosco</a>
        </div>
      </div>
    </div>
  </section>
  <a href="https://wa.me/5544991435010" class="back-to-top d-flex align-items-center justify-content-center" style="background-color:#34af23; border-radius: 20px">
    <i class="bi bi-whatsapp"></i>
  </a>
  <Footer />
</template>


<script>
import TopBar from "../components/TopBar.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "GarantiaCrediario",
  components: {
    TopBar,
    Footer,
  },

    mounted() {

    if (window.localStorage) {

                if (!localStorage.getItem('reload')) {
                    localStorage['reload'] = true;
                    window.location.reload();
                } else {
                    localStorage.removeItem('reload');
                }
    }

  }
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}

.card-body {
  background: #f2f2f2;
  opacity: 0.9;
}

</style>
