<template>
  <section id="segmentos" class="segmentos">
    <div style="margin-top: 50px;"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
          <h3><font face="Sansation_Regular" >Nossos principais segmentos</font></h3>
          <div style="margin-top: 25px;"></div>
        </div>
      </div><br>

      <div class="row">
        <div class="col-lg-3 col-md-6 d-flex align-items-stretch" style="height: 23rem; border-radius: 10px; " data-aos="fade-up">
          <div class="box">
            <h4><i class="fas fa-glasses"></i><font face="Sansation_Regular" > - Ótica</font></h4>
            <p>
              Amplie sua visão de mercado! Nossas soluções de crédito possuem
              informações que garantem a melhor transparencia entre sua ótica e seu
              cliente.
            </p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" style="height: 23rem; border-radius: 10px;" data-aos="fade-up" data-aos-delay="10">
          <div class="box">
            <h4><i class="fa-solid fa-tooth"></i><font face="Sansation_Regular" > - Odontologia</font></h4>
            <p>
              Ajudamos a construir Sorrisos. Com a Creditall, você pode
              transformar os orçamentos em procedimentos.
            </p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" style="height: 23rem; border-radius: 10px;" data-aos="fade-up" data-aos-delay="20">
          <div class="box">
            <h4><i class="fas fa-air-freshener"></i><font face="Sansation_Regular" > - Estética</font></h4>
            <p>
              Autoestima garantida! Com a Garantia de Credito da Creditall você
              aumentará suas vendas, ofertando mais opções de pagamentos para seus
              clientes.
            </p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" style="height: 23rem; border-radius: 10px;" data-aos="fade-up" data-aos-delay="30">
          <div class="box" style="overflow: hidden">
            <h4><i class="fas fa-home"></i><font face="Sansation_Regular" > - Construção & Acabamento</font></h4>
            <p>
              Quem casa quer casa! Parcele conosco seus sonhos. Oferecemos as melhores
              condições de pagamento para que você possa adquirir hoje o que você só
              poderia desfrutar no futuro!
            </p><div style="margin-top:75px;"></div>
          </div>
        </div>
      </div>
      <div style="margin-top:50px;"></div>

      <div class="row">
        <div class="col-lg-3 col-md-6 d-flex align-items-stretch" style="height: 23rem; border-radius: 10px;" data-aos="fade-up" data-aos-delay="40">
          <div class="box" style=" text-overflow: ellipsis;
              overflow: hidden;
              ">
            <h4><i class="fa-solid fa-shoe-prints"></i><font face="Sansation_Regular" > - Calçados & Confecções</font></h4>
            <p>
              Para o segmento de calçados e confecções tenha nosso produto da linha
              "Garantia de Crediário". Com essa solução, você
              aumentará suas vendas ofertando mais crédito e maior prazo com
              100% de garantia.
            </p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" style="height: 23rem; border-radius: 10px;" data-aos="fade-up" data-aos-delay="50">
          <div class="box">
            <h4><i class="fas fa-suitcase"></i><font face="Sansation_Regular" > - Turismo</font></h4>
            <p>
              Viaje agora e pague depois!</p>
            <p>
              A Creditall oferece uma linha completa de produtos que facilitam a aquisição do pacote de
              turismo escolhido pelo seu cliente.</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" style="height: 23rem; border-radius: 10px;" data-aos="fade-up" data-aos-delay="60">
          <div class="box">
            <h4><i class="fas fa-briefcase-medical"></i><font face="Sansation_Regular" > - Cirurgia</font></h4>
            <p>
              Proporcionem a cirurgia dos sonhos sem se preocupar com a
              inadimplência. Oferecemos meios de pagamentos que facilitam a
              vida dos seus pacientes.
            </p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" style="height: 23rem; border-radius: 10px;" data-aos="fade-up" data-aos-delay="70">
          <div class="box">
            <h4><i class="fas fa-car"></i><font face="Sansation_Regular" > - Concessionária</font></h4>
            <p>
              Do popular ao carro de luxo, da moto ao caminhão, a Creditall vai
              ajudar a alavancar suas vendas, de forma segura e garantida.
            </p><div style="margin-top:153px;"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "SectionSegmentos",
};
</script>

<style scoped>
.segmentos {
  padding-top: 10px;
  margin-top: 2rem;
}
.segmentos .box {
  padding: 50px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.3s;
}
.segmentos .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #ed5b5b;
}
.segmentos .box h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: #191919;
}
.segmentos .box p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
}
.segmentos .box:hover {
  background: #BF1B39;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}
.segmentos .box:hover span,
.segmentos .box:hover h4,
.segmentos .box:hover p {
  color: #fff;
}
</style>
