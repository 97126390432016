<template>
  <TopBar />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
        <ol>
          <li class="text-danger"><a href="/">Início</a></li>
          <li>
            <a href="/sou-consumidor" class="text-dark">Sou consumidor</a>
          </li>
        </ol>
        <h2>Emitir Guia</h2>
      </div>
    </div>
  </section>
  <!-- End Breadcrumbs -->

  <section class="inner-page mt-5">
    <div class="container">
      <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-3 order-1 order-lg-2" data-aos="fade-left">
          <img src="assets/images/guia-servicos.png" class="img-fluid" alt="" />
          <div style="margin-top: 100px"></div>
        </div>
        <div class="col-lg-8 pt-4 pt-lg-0 order-2 order-lg-1 content">
          <h4 class="classic-title">
            <span style="border-bottom: 1px solid red">Emitir Ordem de Compra</span>
          </h4>
          <p>Informe o CPF ou CNPJ cadastrado e a senha para continuar:</p>
          <br/>

          <form role="form" class="guia" id="contact-form" method="post">
            <div class="row">
              <div class="col-lg-4" style="margin-top:25px">
                <label for="cnpj">
                  <strong>Documento</strong>
                  <span class="required text-danger">*</span>
                </label>
                <input type="text" placeholder="CPF/CNPJ" value aria-required="true" onchange="AplicarMascaraCpfCnpj(this)" class="form-control" autocomplete="off" required/>
              </div>

              <div class="col-lg-4" style="margin-top:25px">
                <label for="token">
                  <strong>Senha</strong>
                  <span class="required text-danger">*</span>
                </label>
                <input id="token" name="token" type="password" value class="form-control" aria-required="true" autocomplete="off" required/>
              </div>

              <div class="col-md-4 mb-5" style="margin-top:25px">
                <label>&nbsp;</label>
                <br/>
                <a href="#" class="btn btn-danger" style="border-radius: 0px;">Contunuar</a>
              </div>
            </div>
          </form>
        </div>
      </div>
      </div>
    </div>
  </section>
  <a href="https://wa.me/5544991435010" class="back-to-top d-flex align-items-center justify-content-center" style="background-color:#34af23; border-radius: 20px">
    <i class="bi bi-whatsapp"></i>
  </a>
  <Footer />
</template>

<script>
import Footer from "../components/Footer.vue";
import TopBar from "../components/TopBar.vue";

export default {
  name: "EmitirGuia",
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
</style>
