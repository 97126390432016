<template>
  <section id="empresa" class="empresa">
    <div style="margin-top: 50px;"></div>

    <div class="container">
      <div class="row justify-content-">
        <div class="col-lg-12">
          <div class="row justify-content-between">
            <div class="col-lg-5 d-flex justify-content-center" data-aos="fade-right">
              <img src="assets/images/FotoSectionEmpresa.png" class="img-fluid rounded shadow" style="shadow" width="500" height="300" alt=""/>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 justify-content-start content ml-2" data-aos="fade-left">
              <h3 style="font-size: 2.3rem"> <font face="Sansation_Regular" >Creditall</font></h3>
                <p class="color:#95A8B7;" style="margin-top: 2.5rem">
                 A Creditall diferencia-se por oferecer produtos e serviços que
                 abrangem os principais desafios de negócios de sua empresa.
                </p>
                <p style="margin-top: 1.5rem">
                 O uso coordenado de informações consistentes e abrangentes de
                 análise e concessão de crédito, proporcionam alta performance na
                 tomada de decisões, com rapidez, menor risco e maior rentabilidade.
                </p>
                <p style="margin-top: 1.5rem">
                 Nosso modelo de gestão empresarial estimula a inovação, prioriza a
                 satisfação do cliente e a busca contínua da qualidade em nossos
                 serviços.
                </p>
              <a href="/a-empresa" class="btn btn-danger" style="margin-top: 2rem; background: #BF1B39; border-radius: 0px; border-radius: 10px;">Conheça a Creditall</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionEmpresa",
};
</script>

<style scoped>
section {
  padding: 30px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}
.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

</style>
