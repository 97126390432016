<template>
  <TopBar />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
      <ol>
        <li class="text-danger"><a href="/">Início</a></li>
        <li><a href="/#servicos">Produtos e Serviços</a></li>
      </ol>
      <h2>Gestão de cheque</h2>
    </div>
    </div>
  </section>
  <!-- End Breadcrumbs -->

  <section class="inner-page mt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
          <h5 style="border-bottom: 1px solid red">Sobre o Produto</h5>
          <br />
          <p style="font-size: 13px">
            A Creditall disponibiliza para todo o território nacional seu
            serviço Creditall Gestão de Cheques, possibilitando às empresas do
            varejo através da modalidade de venda com cheques à vista e
            pré-datados aumento significativo em seu faturamento. Dentre as
            formas de pagamento mais populares o cheque destaca-se pela
            facilidade de negociação direta entre consumidor e lojista, o
            consumidor participa na definição do melhor prazo de acordo com o
            seu orçamento, outro fator importante é a percepção que o consumidor
            é único e recebe atendimento personalizado propiciando a fidelização
            do mesmo.
          </p>
          <div style="margin-top: 50px"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
          <h6 style="border-bottom: 1px solid red">Benefícios com o Produto:</h6>
          <br />
          <div class="col-md-12" style="margin-left: 20px">
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Garantia de Desempenho de 97% de compensação dos cheques consultados.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Aumento nos recebimentos com cheques, à vista e pré-datados independente da localidade e tempo de abertura de conta.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Maior Lucratividade nas transações com cheques.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Mais segurança nas vendas devido à identificação do perfil e potencial de consumo do emitente.</p>
            <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger"></i> Agilidade nas aprovações de crédito proporcionando diminuição no tempo médio do atendimento.</p>
            <div style="margin-top: 50px"></div>
          </div>
          <div style="margin-top: 50px"></div>
        </div>
      </div>

      <div class="mb-5" data-aos="fade-right">
        <h5 class="text-center">Entre em contato para maiores informações</h5>
        <div class="d-grid gap-2 col-4 mx-auto">
          <a href="/quero-ser-cliente" class="btn btn-danger" style="border-radius: 0px;">Fale conosco</a>
        </div>
      </div>

    </div>
  </section>
  <a href="https://wa.me/5544991435010" class="back-to-top d-flex align-items-center justify-content-center" style="background-color:#34af23; border-radius: 20px">
    <i class="bi bi-whatsapp"></i>
  </a>

  <Footer />
</template>

<script>
import TopBar from "../components/TopBar.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "GestaoCheque",
  components: {
    TopBar,
    Footer,
  },

    mounted() {

    if (window.localStorage) {

                if (!localStorage.getItem('reload')) {
                    localStorage['reload'] = true;
                    window.location.reload();
                } else {
                    localStorage.removeItem('reload');
                }
    }

  }
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
</style>
