<template>
  <div class="preloader">
    <div class="loader">
      <div class="ytp-spinner">
        <div class="ytp-spinner-container">
          <div class="ytp-spinner-rotator">
            <div class="ytp-spinner-left">
              <div class="ytp-spinner-circle"></div>
            </div>
            <div class="ytp-spinner-right">
              <div class="ytp-spinner-circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <HeaderSlide />

  <SectionEmpresa />

  <SectionSegmentos />

  <SectionServicos />

  <!--<SectionLGPD /> -->

  <SectionQueroSerCliente />

  <Footer />

  <a href="https://wa.me/5544991435010" class="back-to-top d-flex align-items-center justify-content-center" style="background-color:#34af23; border-radius: 20px">
    <i class="bi bi-whatsapp"></i>
  </a>

</template>

<script>
import HeaderSlide from "../components/HeaderSlide.vue";
import SectionEmpresa from "../components/SectionEmpresa.vue";
import SectionSegmentos from "../components/PrincipaisSegmentos.vue";
import SectionServicos from "../components/SectionServicos.vue";
//import SectionLGPD from "../components/SectionLGPD.vue";
import SectionQueroSerCliente from "../components/SectionQueroSerCliente.vue";
//import SectionContato from "../components/SectionContato.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    HeaderSlide,
    SectionEmpresa,
    SectionSegmentos,
    SectionServicos,
    //SectionLGPD,
    SectionQueroSerCliente,
    //SectionContato,
    Footer,
  },
};
</script>

<style scoped>

p {
  margin: 0px;
}

body {
  height: 200vh;
}

.cookies-container {
  color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
  position: fixed;
  z-index: 1000;
}

.cookies-content {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  background: rgba(50, 50, 50, 0.9);
  max-width: 510px;
  border-radius: 5px;
  padding: 1rem;
  margin: 50px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  opacity: 0;
  transform: translateY(1rem);
  animation: slideUp 0.5s forwards;
}



@keyframes slideUp {
  to {
    transform: initial;
    opacity: initial;
  }
}

.cookies-save {
  grid-column: 2;
  background: #e84e4e;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px;
  align-self: center;
  height: 50px;
  font-size: 1rem;
}

@media screen and (max-width: 740px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

@media screen and (device-aspect-ratio: 40/71) {
  #HeaderSlide{
    width: 100%;
    font-size: 9px;
  }
}

@media screen and (device-aspect-ratio: 375/667) {
  #HeaderSlide{
    width: 100%;
    font-size: 10px;
  }
}

@media screen and (device-aspect-ratio: 360/640) {
  #HeaderSlide{
    width: 100%;
    font-size: 10px;
  }
}

@media screen and (device-aspect-ratio: 375/812) {
  #HeaderSlide{
    width: 100%;
    font-size: 10px;
  }
}

@media screen and (device-aspect-ratio: 280/653) {
  #HeaderSlide{
    width: 100%;
    font-size: 7px;
  }
}

@media screen and (device-aspect-ratio: 360/760) {
  #HeaderSlide{
    width: 100%;
    font-size: 10px;
  }
}

@media screen and (device-aspect-ratio: 360/800) {
  #HeaderSlide{
    width: 100%;
    font-size: 10px;
  }
}

@media screen and (device-aspect-ratio: 320/533) {
  #HeaderSlide{
    width: 100%;
    font-size: 8px;
  }
}

@media screen and (device-aspect-ratio: 320/480) {
  #HeaderSlide{
    width: 100%;
    font-size: 8px;
  }
}

@media screen and (device-aspect-ratio: 240/320) {
  #HeaderSlide{
    width: 100%;
    font-size: 5px;
  }
}


</style>
