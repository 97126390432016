<template>
  <footer id="footer" >
    <div id='footerInput'>
      <div class='container contInputFooter'>
        <div class ='textInputFooter'>
          <h3 style="color:#fff; font-weight:200; " >Matenha-se informado</h3>
          <h4 style="color:#fff; font-weight:200;" >Receba a nossa newsletter</h4>
        </div>

        <form id='divInputFooter' ref="form" @submit.prevent="sendEmail">
          <input id='inputFooterEmail' v-model="nome" type="email" name="email" class="form-control" placeholder="Digite seu email" required/>
          <button v-on:click="salvar" type="submit" class="btn" style="border-radius: 3px; background-color:#BF1B39; color:#fff">Enviar</button>
        </form>
      </div>
    </div>
    <div id='footers' class="footer-top col-lg-12 container">
      <div class = 'divIconFooter'  >
        <div class = 'divIcons2' style="display:flex">
          <div id='divFooterIcon'>
            <div id='iconFooter'>
              <i class="bi bi-telephone-outbound"></i>
            </div>
            <h5 id="textIcon" >41 3205-1333</h5>
          </div>

          <div id='divFooterIcon'>
            <div id='iconFooter'>
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <div>
              <h5 id="textIcon" >ONDE ESTAMOS?</h5>
            </div>
          </div>
        </div>
        <div style="display:flex" >
              <a target="_blank" href="https://www.facebook.com/creditalloficial">
            <div id='iconFooter2'>
                <i  class="bi bi-facebook"></i>
            </div>
              </a>
              <a target="_blank" href="https://www.instagram.com/creditalloficial/">
            <div id='iconFooter2'>
                <i class="bi bi-instagram"></i>
            </div>
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/creditall-holding-empresarial-eireli/about/">
            <div id='iconFooter2'>
                <i class="bi bi-linkedin"></i>
            </div>
              </a>
              <a target="_blank" href="https://www.youtube.com/@creditallmarketing5282">
            <div id='iconFooter2'>
                <i class="bi bi-youtube"></i>
            </div>
              </a>
              <a target="_blank" href="https://www.google.com/search?q=Creditall&stick=H4sIAAAAAAAA_-NgU1I1qLA0SUlONbawMEuxtDRJSzG2MqhINUoyMktNTDVLSUsxNTRMXMTK6VyUmpJZkpiTAwCJGNUJNQAAAA&hl=pt-BR&mat=CdD2l4IUGH_YElcBpsFACzvJo30D9ZVCAK6JoY-mq9GQx3H0LDeVU32O9X2i58ciAtz87gyIFIDOFZsRzAsY-aDU50xd0v7Px735YFlNqsP32oeztLVWpPjFUn8ADXhaGAs&authuser=0">
            <div id='iconFooter2'>
                <i class="bi bi-google"></i>
            </div>
              </a>
        </div>
      </div>
      <div class='divCopyrightFooter'>
        <div>
          <ul class='navFooter' style="padding: 0 ">
              <li class="navFooterItem"><a href="#servicos">Garantia de Crédito</a></li>
              <li class="navFooterItem">|</li>
              <li class="navFooterItem"><a href="#garantiaaluguel">Garantia de Aluguel</a></li>
              <li class="navFooterItem">|</li>
              <li class="navFooterItem"><a href="#plataformacredito">Plataforma de Crédito</a></li>
          </ul>
         
          <ul class='navFooter' style="padding: 0 ">
            <li class="navFooterItem"><a href="#">Inicio</a></li>
            <li class="navFooterItem">|</li>
            <li class="navFooterItem"><a href="/lgpd">LGPD</a></li>
            <li class="navFooterItem">|</li>
            <li class="navFooterItem"><a href="https://siscredit.com.br/sisrepr/" target="_blanck">Licenciado</a></li>
            <li class="navFooterItem">|</li>
            <li class="navFooterItem"><a href="/desenvolvedores">Área destinada a desenvolvedores</a></li>
          </ul>

          <div class="copyright" style="font-size: 12px; margin:10px 0">
              © 2008 - {{ new Date().getFullYear() }} | <span><a href="/">Creditall Holding Empresarial</a></span> - Todos os direitos reservados
            <strong></strong>
          </div>
        </div>
        <div class='divLogo'>
          <img src="../../public/assets/images/logo_creditall.png" alt="">
        </div>
                
      </div>
    </div>

  </footer>

<!-- ======= Cookies  -->
    <div class="box-cookies hide" data-aos="fade-up">
        <p class="msg-cookies">Queremos garantir a melhor navegação e personalizar sua experiência no nosso site. Por isso, coletamos informações pessoais de preferências e interesses por meio de cookies e outras tecnologias semelhantes. Entenda como usamos os dados armazenados na nossa
        <a href="/politicas-privacidades" class="text-decoration-underline">Política de Cookies</a>.</p>
        <button v-on:click="acceptCookies" class="btn-cookies">Prosseguir</button>
    </div>
 <!--End Cookies -->

</template>


<script>
/*(function (w, d, s, u) {
  w.RocketChat = function (c) {
    w.RocketChat._.push(c);
  };
  w.RocketChat._ = [];
  w.RocketChat.url = u;
  var h = d.getElementsByTagName(s)[0],
    j = d.createElement(s);
  j.async = true;
  j.src =
    "https://chat.creditall.com.br/livechat/rocketchat-livechat.min.js?_=201903270000";
  h.parentNode.insertBefore(j, h);
})(window, document, "script", "https://chat.creditall.com.br/livechat");*/


//if (localStorage.creditallCookie) {
//    document.querySelector('.box-cookies').classList.add('hide');
//}

//const acceptCookies = () => {

  //  document.querySelector('.box-cookies').classList.add('hide')
    //localStorage.setItem("creditallCookie", "accept");
//}

//const btnCookies = document.querySelector(".btn-cookies");

//btnCookies.addEventListener('click', acceptCookies);

/* 
  <div class="footer-top col-lg-12 container">
      <div class="row">
      <div class="col-lg-4 col-md-6">

          <div class="footer-info">
          <h3><img href="/" width="150px" style="margin-top: 1px" src="../../public/assets/images/logo_creditall_inverso.png"/></h3>
          <a href="https://goo.gl/maps/RvKqPfXkoQ31Lfdg9" target="blanck">
              <p>
              Rua 14 de Dezembro, 45<br />
              Centro | Mandaguaçu - PR
              </p></a>

          <div style="margin-top: 50px"></div>

          <p>Telefone: (41) 3205-1333</p>
          <p>E-mail: <a href="mailto:contato@creditall.com.br">contato@creditall.com.br<br/></a></p>

          <div class="social-links mt-3">
              <a href="https://pt-br.facebook.com/creditall.inteligenciadecredito/" target="blanck" class="twitter"><i class="bi bi-facebook"></i></a>
              <a href="http://wa.me/554140421642" target="blanck" class="twitter"><i class="bi bi-whatsapp"></i></a>
          </div>
          </div>
      </div>

      <div class="col-lg-2 col-md-6 footer-links">
          <h4>Acesso rápido</h4>
          <ul>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="/#">⠀Inicio</a></li>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="/#empresa">⠀Sobre</a></li>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="/#servicos">⠀Serviços</a></li>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="/#lgpd">⠀LGPD</a></li>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="/#contato">⠀Contato</a></li>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="/trabalhe-conosco">⠀Trabalhe Conosco</a></li>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="https://siscredit.com.br/sisrepr/" target="_blanck">⠀Licenciado</a></li>
          </ul>
      </div>

      <div class="col-lg-2 col-md-6 footer-links">
          <h4>Produtos e serviços<span class="position-absolute top-0 translate-middle badge rounded-pill bg-danger" style="background: #BF1B39; font-size: 10px">Novo<span class="visually-hidden">unread messages</span></span></h4>
          <ul>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="/#servicos">⠀Garantia de Crédito</a></li>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="/#garantiaaluguel">⠀Garantia de Aluguel</a></li>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="/#plataformacredito">⠀Plataforma de Crédito</a></li>
          </ul>
      </div>

      <div class="col-lg-2 col-md-6 footer-links">
          <h4>Área do Cliente</h4>
          <ul>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="/sou-lojista">⠀Sou Lojista</a></li>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="/sou-consumidor">⠀Sou Consumidor</a></li>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="https://www.siscredit.com.br/boletos/extrato.php">⠀Sou Locatário</a></li>
          </ul>
      </div>

      <div class="col-lg-2 col-md-6 footer-links">
          <h4>Área de Desenvolvimento</h4>
          <ul>
          <li><i class="fas fa-angle-right" style="color:#BF1B39"></i><a href="/desenvolvedores">⠀Área destinada a<br>⠀desenvolvedores</a></li>
          </ul>
      </div>

      </div>
       
    </div>
 */

 
export default {
  name: "Footer",
  methods: {
     sendEmail() {
            // Então redireciona para a nova página
            window.location.href = "/quero-ser-cliente";
            window.scrollTo(0, 0);
    }
  },
  mounted(){
      if (!localStorage.creditallCookie) {
        document.querySelector('.box-cookies').classList.remove('hide');
      }

      const acceptCookies = () => {

        document.querySelector('.box-cookies').classList.add('hide')
        localStorage.setItem("creditallCookie", "accept");
    }

    const btnCookies = document.querySelector(".btn-cookies");

    btnCookies.addEventListener('click', acceptCookies);

    

    //  acceptCookies(){
    //    document.querySelector('.box-cookies').classList.add('hide')
    //    localStorage.setItem("creditallCookie", "accept");
    //  }
  },
};
</script>


<style scoped>
#footer {
  background: #fff;
  padding: 0 0 30px 0;
  
  font-size: 14px;
}
#footer .footer-top {
  padding: 60px 0 10px 0;
}
#footer .footer-top .footer-info {
  margin-bottom: 15px;
  background: #303033;
  color: #fff;
  border-top: 4px solid #BF1B39;
  text-align: center;
  padding: 30px 20px;
}
#footer .footer-top .footer-info h3 {
  font-size: 36px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}
#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
}
#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #262626;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
  background: #BF1B39;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #BF1B39;
  font-size: 18px;
  line-height: 1;
}
#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
#footer .footer-top .footer-links ul a:hover {
  color: #BF1B39;
}
#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4;
}
#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}
#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #BF1B39;
  color: #fff;
  transition: 0.3s;
  border-radius: 4;
}
#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #BF1B39;
}
#footer .copyright {
  padding-top: 30px 0 0 0;
}
#footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
#footer .credits a {
  color: #BF1B39;
}

/*--------------------------------------------------------------
# Cookies
--------------------------------------------------------------*/
.hide {
  display: none !important;
}

.box-cookies {
  position: fixed;
  flex-direction: column;
  background: rgba(0, 0, 0, .8);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
  margin-left: 20px;
  margin-right: 15px;
  border-radius: 5px;
  padding: 1rem;
  z-index: 99999;
  bottom: 1rem;
  align-items: center;
  justify-content: space-between;
}

.box-cookies .msg-cookies,
.box-cookies .btn-cookies {
  padding: 5px;
  color: #fff;
  font-size: 13px;
}

.box-cookies .btn-cookies {
  background: #BF1B39;
  cursor: pointer;
  align-self: normal;
  position: relative;
  display: block;
  color: white;
  font-size: 12px;
  text-decoration: none;
  margin: 5px 0;
  border: 2px solid #BF1B39;
  border-radius: 0px;
  padding: 10px 30px;
  text-transform: uppercase;
  overflow: hidden;
  transition: 1s all ease;
}

#footerInput{
  width: 100%;
  padding: 40px 0;
  background-color: #848688;
  
}

#inputFooterEmail{
  width: 350px;
  height: 35px;
  margin: 0 5px;
}

#divInputFooter{
  display:  flex;
  align-items: center;
}

#divFooterIcon{

  display: flex;
  align-items: center;
  margin: 0 10px 0 0;

}
#divFooterIcon:hover{
  transform: translateY(-2px) translateX(-1px);

}
#textIcon{
  margin: 0;
  color: #BF1B39;
}

#iconFooter{
  font-size: 20px;
  padding: 10px;
  border: solid 1px #BF1B39;
  border-radius: 5px;
  color: #BF1B39;
  margin: 0 10px 0 0;
}


#branco{
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  margin: 45px 0;
}
#footers{
  width: 100%;
  justify-content: space-between;
  background-color: white;

}
#iconFooter2{
  font-size: 21px;
  padding: 10px;
  border-radius: 5px;
  background-color: #848688;
  color: white;
  margin: 0 5px; 
}
#iconFooter2:hover{
  transform: translateY(-2px) translateX(-1px);
}
#hederFooter{
  display: flex;
}

#navFooter{
  color: #848688;
}

#textNavFooter{
  color: #848688;
}
.navFooter{
  display: flex;
  flex-wrap: wrap
}
.navFooterItem{
  margin: 0 10px 0 0;
}
.contInputFooter{
  display: flex;
  justify-content: space-between;
}
.divCopyrightFooter{
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .contInputFooter{
    flex-direction: column;
  }
  #divFooterIcon{
    margin: 10px 0;
  }
  .divIconFooter{
    flex-direction: column;
    height: 20vh;
    align-items: center;
    

  }
  .contInputFooter{
    align-items: center;
  }
  .divLogo{
    display: none;
  }
  
  .divIcons2{
    width: 100%;
    justify-content: space-around;
  }
  .navFooter{
    justify-content: center;
  }
  .divCopyrightFooter{
    justify-content: center;
  }
  .copyright{
    text-align: center;
  }
}

@media screen and (max-width: 420px) {
 #divInputFooter{
    width: 80%;

  }
}

.divIconFooter{
  display: flex;
  justify-content: space-between;
  margin: 0 0 35px 0;
}

.divLogo{
  width: 30%;
  -webkit-writing-mode: vertical-rl;
  text-align: end;

}
</style>
