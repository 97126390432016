<template>
  <TopBar />

  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
      <ol>
        <li class="text-danger"><a href="/">Início</a></li>
      </ol>
      <h2>Politicas e Privacidades</h2>
      </div>
    </div>
  </section>
  <!-- End Breadcrumbs -->

  <section class="inner-page mt-5">
    <div class="container">
      <div class="col-lg-12">
      <p>
        “Cookies” é o termo utilizado aos cookies e outras tecnologias similares cobertas pela
        Diretiva da União Europeia sobre privacidade em comunicações eletrônicas.
      </p>

          <div id="accordion">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                  <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    O que é um cookie? <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>

              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                    <p>Cookie é um dado que o website solicita ao seu navegador para armazenar no seu computador ou dispositivo móvel. O cookie permite que o website memorize suas ações ou preferências ao longo do tempo.</p>
                    <p>A maioria dos navegadores da Internet aceitam cookies; entretanto, os usuários podem configurar seus navegadores para recusar certos tipos de cookies ou cookies específicos. Além disso, os usuários podem apagar os cookies a qualquer momento.</p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Por que usamos cookies? <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body">
                    <p>Usamos cookies para aprender como você interage com o nosso conteúdo e para melhorar sua experiência ao visitar o nosso website.
                        Por exemplo, alguns cookies lembram o seu idioma ou preferências para que você não tenha que efetuar estas escolhas repetidamente
                        sempre que visitar um dos nossos websites. Além disso, os cookies permitem que ofereçamos um conteúdo específico, tais como vídeos
                        no(s) nosso(s) website(s). Podemos empregar o que aprendemos sobre o seu comportamento no(s) nosso(s) website(s) para oferecer
                        anúncios direcionados em website(s) de terceiros em um esforço para “reapresentar” nossos produtos e serviços para você.
                    </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Quais os tipos de Cookies que utilizamos? <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body">
                    <p><strong>Cookies Primários e Cookies de Terceiros:</strong> Utilizamos tanto cookies primários quanto cookies de terceiros no nosso website. Cookies primários são utilizados para identificar preferências de idioma e local ou facilitar a funcionalidade básica do site. Cookies de terceiros pertencem e são administrados por outras empresas, tais como Google e outros provedores de serviços.</p>
                    <p><strong>Cookies da Sessão:</strong> São cookies temporários que são utilizados para lembrar de você durante o curso da sua visita ao website, e eles perdem a validade quando você fecha o navegador.</p>
                    <p><strong>Cookies Persistentes:</strong> São utilizados para lembrar suas preferências do website e permanecem no seu desktop ou dispositivo móvel mesmo depois de você fechar o seu navegador ou efetuar uma reinicialização. Usamos estes cookies para analisar o comportamento do usuário e estabelecer padrões, de modo que possamos melhorar a funcionalidade do nosso website para você e outros que visitam o(s) nosso(s) website(s). Estes cookies também permitem que possamos oferecer anúncios segmentados e medir a eficácia do nosso site e a funcionalidade de tais anúncios.</p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFour">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Como os Cookies de terceiros são usados? <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                <div class="card-body">
                    <p>
                      Para algumas das funções dentro dos nossos websites usamos fornecedores terceirizados, por exemplo, quando você visita uma
                      página com um vídeo incorporado ou proveniente do YouTube. Estes vídeos ou links (e qualquer outro conteúdo de fornecedores
                      terceirizados) podem conter cookies de terceiros, e nós recomendamos consultar as políticas de privacidade destes terceiros
                      nos seus websites para informações relativas a sua utilização de cookies.
                    </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFive">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Como remover os Cookies? <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                <div class="card-body">
                    <p>Caso você queira remover os cookies utilizados pela Creditall de seu navegador, siga os passos abaixo:</p>
                    <p>• Localize o menu de configurações/opções/preferências de seu navegador;</p>
                    <p>• Dentro da área de configurações, localize a seção de Segurança e Privacidade;</p>
                    <p>• Em Segurança e Privacidade, procure pela opção Limpar/Excluir Cookies;</p>
                    <p>• Após o procedimento, reinicie seu browser.</p>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
<div style="margin-top: 75px"></div>

  </section>
  <a href="https://wa.me/5544991435010" class="back-to-top d-flex align-items-center justify-content-center" style="background-color:#34af23; border-radius: 20px">
    <i class="bi bi-whatsapp"></i>
  </a>
  <Footer />
</template>

<script>
import Footer from "../components/Footer.vue";
import TopBar from "../components/TopBar.vue";

export default {
  name: "LGPD",
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style scoped>
.btn-link {
    font-weight: 400;
    color: #444444;
    text-decoration:none;
}

.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}

.accordion-button:not(.collapsed) {
  color: #444444;
  background-color: #e4e4e4;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
</style>
